// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyDvGQGYU2TO0OBnz9bItcDdx4yB0HeTfVM",

  authDomain: "savecash-44f88.firebaseapp.com",

  projectId: "savecash-44f88",

  storageBucket: "savecash-44f88.appspot.com",

  messagingSenderId: "580733728821",

  appId: "1:580733728821:web:32a2fc70830a85faa5997c",

  measurementId: "G-X0LVH7GKP1"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const db = getFirestore();
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = (callback: Function) => {
  signInWithPopup(auth, provider).then(
    (result) => {
      return callback(true, result)
    }
  ).catch((error) => {
    console.error(error)
    return callback(false)
  })
}